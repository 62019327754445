// //angular app
// var angularApp = angular.module('configurator', []);

// angularApp.controller('configController', ['$http', '$scope', '$rootScope', '$timeout',  function ($http, $scope, $rootScope, $timeout) {
//     var today = new Date(),
//         c = $scope

//     c.step = 1

//     c.changeStep = function(step) {
//         c.step = c.step + step
//     }


// }]);